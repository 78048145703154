@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Mulish:wght@200;300;400;500;600;700;800;900;1000&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&family=Ubuntu:wght@300;400;500;700&display=swap');

*{  
    font-family: 'Plus Jakarta Sans', sans-serif;

}
.font-heading{
    font-family: 'Abril Fatface', cursive;
}
.primary-bg{
  background-color: #0084FF; 
}
.primary-text{
    color: #0084FF; 
  }
.border-shadow{
    box-shadow: 0px 30px 60px 20px #EAF5FF;
}
.max-w-1400{
    max-width: 1400px;
}
.bg-radiant{
    fill: radial-gradient(50.01% 50.01% at 74.39% 26.7%, #0084FF 0%, rgba(0, 132, 255, 0.00) 100%);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.bg-blue-grediant{
    background: var(--primary-color, #0084FF);
box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.25);
}
.bg-gray-grediant:hover{
    background:#0084FF ;
    fill: radial-gradient(50.01% 50.01% at 74.39% 26.7%, #0084FF 0%, rgba(0, 132, 255, 0.00) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.25);
}
.bg-gray-grediant{
    background: #F4FAFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.bg-linear-gredient{
    background: linear-gradient(180deg, rgba(48, 101, 181, 0.33) 0%, rgba(255, 255, 255, 0.00) 100%);
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.bg-linear-gredient:hover{
    background: linear-gradient(180deg, rgba(48, 101, 181, 0.33) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.25);
}
.bg-blue-light{
    background: rgba(17, 4, 122, 0.20);
}


.bg-gray-grediant-active{
    background:#0084FF ;
    fill: radial-gradient(50.01% 50.01% at 74.39% 26.7%, #0084FF 0%, rgba(0, 132, 255, 0.00) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.25);
}

.bg-contact{
    background-image:url('assets/contact_us_form_bg.png');
    background-position: center;
    /* background-repeat: no-repeat; */
    background-size: cover;
    /* background-size: cover; */
}

.bg-home-btn{
border-radius: 20px;
border: 1px solid #3293EE;
background: #005EB5;
box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.25);
}